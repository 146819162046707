import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OfertaPage = () => (
  <Layout>
    <SEO title="Oferta - Kancelaria Prawna RIO" />
    <section className="top content-page">
      <div className="wrap">
        <h2>Oferta</h2>
      </div>
    </section>
    {/* <section className="content-page featured-content features">
      <div className="wrap">
        <div className="column content-column">
          <h5>Konsumenci</h5>
          <h2>Upadłość konsumencka</h2>
          <p>
            Stanowi drogę do oddłużenia osób fizycznych nieprowadzących
            działalności gospodarczej. Polega na umorzeniu lub redukcji
            zobowiązań kredytowych w przypadku zaistnienia niezawinionej
            niewypłacalności dłużnika.
          </p>
        </div>
        <div className="column content-column">
          <h5>Przedsiębiorcy</h5>
          <h2>Upadłość gospodarcza</h2>
          <p>
            Zalecana przedsiębiorcom, czyli osobom fizycznym wpisanym do
            rejestru przedsiębiorców (CEIDG), którzy zaprzestali wykonywać
            wymagalnych zobowiązań pieniężnych powyżej trzech miesięcy.
          </p>
        </div>
        <div className="column content-column">
          <h5>Przedsiębiorcy</h5>
          <h2>Restrukturyzacja</h2>
          <p>
            Przeprowadzana zamiast upadłości. Bazuje na procedurze
            naprawczo-oddłużeniowej, która w swoich działaniach reorganizuje
            przedsiębiorstwo przechodzące chwilowe problemy natury finansowej.
          </p>
        </div>
        <div className="column content-column">
          <h5>Nowość</h5>
          <h2>Symulacje rozpraw sądowych</h2>
          <p>
            Zalecana przedsiębiorcom, czyli osobom fizycznym wpisanym do
            rejestru przedsiębiorców (CEIDG), którzy zaprzestali wykonywać
            wymagalnych zobowiązań pieniężnych powyżej trzech miesięcy.
          </p>
        </div>
      </div>
    </section> */}
    <section className="content">
      <div className="wrap">
        <h2>Legalizacja pobytu</h2>
        <p>
          W ramach działalności kancelarii, świadczymy pomoc prawną m. in. w
          zakresie:
        </p>
        <ol>
          <li>
            reprezentacji cudzoziemca w sądach i przed organami administracji
            państwowej;
          </li>
          <li>
            reprezentacji cudzoziemca przy postępowaniu legalizacji pobytu na
            terenie Rzeczypospolitej Polskiej, w przedmiocie:
            <ul>
              <li>zezwolenia na pobyt czasowy;</li>
              <li>zezwolenia na pobyt czasowy i pracę;</li>
              <li>
                zezwolenia na pobyt czasowy w celu wykonywania pracy w zawodzie
                wymagającym wysokich kwalifikacji (tzw. Niebieska Karta);
              </li>
              <li>
                zezwolenia na pobyt czasowy w celu wykonywania pracy przez
                cudzoziemca delegowanego przez pracodawcę zagranicznego na
                terytorium RP;
              </li>
              <li>
                zezwolenia na pobyt czasowy w celu wykonywania działalności
                gospodarczej;
              </li>
              <li>
                zezwolenia na pobyt czasowy w celu kształcenia się na studiach;
              </li>
              <li>
                zezwolenia na pobyt czasowy w celu w celu prowadzenia badań
                naukowych;
              </li>
              <li>
                zezwolenia na pobyt czasowy w celu dla członków rodzin
                cudzoziemców;
              </li>
              <li>
                zezwolenia na pobyt czasowy dla członków rodzin obywatela RP;
              </li>
              <li>
                pobyt na terytorium RP cudzoziemców będących ofiarami handlu
                ludźmi;
              </li>
              <li>
                zezwolenia na pobyt w RP w przypadkach wskazania innych
                okoliczności;
              </li>
              <li>zezwolenia na pobyt stały;</li>
            </ul>
          </li>
          <li>zezwolenia na pobyt rezydenta długoterminowego UE;</li>
          <li>reprezentacji  przy zakupie nieruchomości;</li>
          <li>pomocy przy rejestracji pojazdów;</li>
          <li>pomocy przy uzyskaniu lub wymianie praw jazdy;</li>
          <li>
            doradztwie w zakresie rozpoczęcia prowadzenia działalności
            gospodarczej,
          </li>
          <li>
            pomocy przy uzyskiwaniu innych niezbędnych zezwoleń oraz na bieżącej
            obsłudze prawnej itp.
          </li>
        </ol>
        <h2>Sprawy rodzinne</h2>
        <p>
          Korzystając z usług naszej Kancelarii pomożemy Państwu zarówno w
          napisaniu pozwu rozwodowego lub separację, wniosku o udzielenie
          zabezpieczenia, czy pisma zawierającego wnioski dowodowe. Ponadto
          oferujemy profesjonalną reprezentację przed sądem. Wskażemy jak
          przebiega proces o rozwód/separację, oraz na co należy zwrócić
          szczególną uwagę, w tym jakie dokumenty skompletować. W ramach
          świadczonej pomocy wspieramy w sprawach dotyczących między innymi:
        </p>
        <ul>
          <li>rozwodu,</li>
          <li>separacji,</li>
          <li>ustalenia miejsca pobytu małoletnich dzieci,</li>
          <li>kontaktów, jeżeli taka będzie wola stron postępowania,</li>
          <li>
            alimentów na małoletnie dzieci, w tym o udzielenie zabezpieczenia na
            czas trwania sprawy rozwodowej,
          </li>
          <li>
            sposobie korzystania ze wspólnie zajmowanego przez strony mieszkania
            (domu),
          </li>
          <li>władzy rodzicielskiej,</li>
          <li>podziału majątku wspólnego i in.</li>
        </ul>
        <h2>Działalność gospodarcza</h2>
        <p>
          Rejestracja działalności gospodarczej w Polsce może być dla
          cudzoziemców nieco skomplikowana, szczególnie jeśli nie porozumiewają
          się oni biegle w języku polskim. W takim przypadku warto skorzystać z
          pomocy specjalistów, którzy biegle mówią w języku ukraińskim oraz
          rosyjskim. W przypadku chęci założenia przez Państwa firmy np.
          jednoosobowej działalności gospodarczej, jak i założenia spółki
          kapitałowej, nasza kancelaria udzieli wsparcia oraz wyczerpujących
          informacji dotyczących formalności i procedur, które należy wypełnić,
          by zgodnie z przepisami wkroczyć na polski rynek.
        </p>
      </div>
    </section>
  </Layout>
)

export default OfertaPage
